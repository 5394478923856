import {startCase} from 'lodash';

export default {
  created() {
    let title = this.$route?.meta?.title;

    if (!title) {
      title = startCase(this.$route?.meta?.name);
    }

    if (title) {
      document.title = `${title} - Admin`;
    }
  },
};
