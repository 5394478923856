import {Cloudinary} from 'cloudinary-core';

export default {

  install(Vue) {
    Vue.prototype.cloudinary = new Cloudinary({

      cloud_name: process.env.VUE_APP_CLOUDINARY_CLOUD_NAME,
      secure: true,
    });

    Vue.prototype.getTransformationUrl = (image, options) => {
      return image.publicId ? Vue.prototype.cloudinary.url(image.publicId, options) : image.url;
    };
  },
};
