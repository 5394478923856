// Import all the trackers
import * as segment from '@/assets/segment';

const _trackers = [
  // List of all the trackers we have
  segment,
];

const _propagate = (event, customer, properties) => {
  // Only track on production
  if (process.env.NODE_ENV !== 'production') {
    return;
  }

  _trackers.forEach(tracker => {
    // If this event is in the tracker's ignore list, skip it
    if (tracker._ignoreList.includes(event)) {
      return;
    }

    // A tracker may or may not want to track this event
    if (typeof tracker.trackEvent !== 'function') {
      return;
    }

    // Each tracker will have its own implementation
    tracker.trackEvent(event, customer, properties);
  });
};

export default {
  /**
   * This method is called in components like so:
   * this.$store.dispatch('events/track', { ... });
   */
  actions: {
    track({state}, {event, customer, properties}) {
      _propagate(event, customer, properties);
    },
  },
  namespaced: true,
  state() {
    return {};
  },
};
