import Permissions from '@hellochef/shared-js/enums/Permissions';

export default [
  {
    path: '/users',
    component: () => import('@/views/users/Index.vue'),
    meta: {
      name: 'users',
      requiresAuth: true,
      permissions: {
        view: Permissions.CUSTOMERS_VIEW,
        create: Permissions.CUSTOMERS_CREATE,
        update: Permissions.CUSTOMERS_UPDATE,
        delete: false,
      },
    },
  },
  {
    path: '/users/:id',
    component: () => import('@/views/users/Edit.vue'),
    meta: {
      name: 'users',
      requiresAuth: true,
      search: '/users',
      permissions: {
        view: Permissions.CUSTOMERS_VIEW,
        create: Permissions.CUSTOMERS_CREATE,
        update: Permissions.CUSTOMERS_UPDATE,
        delete: false,
      },
    },
  },
  {
    path: '/users/:id/roles-permissions',
    component: () => import('@/views/users/rolesAndPermissions/Index.vue'),
    meta: {
      name: 'users',
      requiresAuth: true,
      hideSearch: true,
      permissions: {
        view: Permissions.SUPER_ADMIN,
        create: false,
        update: false,
        delete: false,
      },
    },
  },
  {
    path: '/roles-permissions',
    component: () => import('@/views/rolesAndPermissions/Index.vue'),
    meta: {
      name: 'rolesAndPermissions',
      requiresAuth: true,
      hideSearch: true,
      permissions: {
        view: Permissions.SUPER_ADMIN,
        create: Permissions.SUPER_ADMIN,
        update: Permissions.SUPER_ADMIN,
        delete: Permissions.SUPER_ADMIN,
      },
    },
  },
  {
    path: '/roles-permissions/:id',
    component: () => import('@/views/rolesAndPermissions/Edit.vue'),
    meta: {
      name: 'rolesAndPermissions',
      hideSearch: true,
      requiresAuth: true,
      permissions: {
        view: Permissions.SUPER_ADMIN,
        create: Permissions.SUPER_ADMIN,
        update: Permissions.SUPER_ADMIN,
        delete: Permissions.SUPER_ADMIN,
      },
    },
  },
  {
    path: '/subscriptions',
    component: () => import('@/views/subscriptions/Index.vue'),
    meta: {
      name: 'subscriptions',
      requiresAuth: true,
      permissions: {
        view: Permissions.SUBSCRIPTIONS_VIEW,
        create: Permissions.SUBSCRIPTIONS_CREATE,
        update: Permissions.SUBSCRIPTIONS_UPDATE,
        delete: false,
      },
    },
  },
  {
    path: '/subscriptions/:id',
    component: () => import('@/views/subscriptions/Edit.vue'),
    meta: {
      name: 'subscriptions',
      requiresAuth: true,
      search: '/subscriptions',
      permissions: {
        view: Permissions.SUBSCRIPTIONS_VIEW,
        create: Permissions.SUBSCRIPTIONS_CREATE,
        update: Permissions.SUBSCRIPTIONS_UPDATE,
        delete: false,
      },
    },
  },
  {
    path: '/orders',
    component: () => import('@/views/orders/Index.vue'),
    meta: {
      name: 'orders',
      requiresAuth: true,
      permissions: {
        view: Permissions.ORDERS_VIEW,
        create: Permissions.ORDERS_CREATE,
        update: Permissions.ORDERS_CREATE,
        delete: false,
      },
    },
  },
  {
    path: '/orders/:id',
    component: () => import('@/views/orders/Edit.vue'),
    meta: {
      name: 'orders',
      requiresAuth: true,
      search: '/orders',
      permissions: {
        view: Permissions.ORDERS_VIEW,
        create: Permissions.ORDERS_CREATE,
        update: Permissions.ORDERS_CREATE,
        delete: false,
      },
    },
  },
  {
    path: '/order-change-requests',
    component: () => import('@/views/orderChangeRequests/Index.vue'),
    meta: {
      name: 'order-change-requests',
      requiresAuth: true,
      permissions: {
        view: Permissions.ORDER_CHANGE_REQUESTS_VIEW,
        create: Permissions.ORDER_CHANGE_REQUESTS_CREATE,
        update: Permissions.ORDER_CHANGE_REQUESTS_UPDATE,
        delete: false,
      },
    },
  },
];
