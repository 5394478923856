import Permissions from '@hellochef/shared-js/enums/Permissions';

export default [
  {
    path: '/reports/recipes',
    component: () => import('@/views/reports/recipes/Index.vue'),
    meta: {
      name: 'reports',
      requiresAuth: true,
      permissions: {
        view: Permissions.REPORTS_RECIPES_VIEW,
        create: false,
        update: false,
        delete: false,
      },
    },
  },
];
