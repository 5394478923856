import router from '@/router';
import store from '@/store';
import axios from 'axios';
import Vue from 'vue';
import {getCookie} from '@hellochef/shared-js/helpers';

function sendLogs(ddLogger, {response, route, config, message}) {
  try {
    const journey = ddLogger.journey || route.meta?.name || route.name || route.path;

    const logResponse = ddLogger
      .setMessage(message)
      .setResponse(response)
      .setConfig(config)
      .setRoute(route)
      .setName(journey)
      .sendLogs();

    if (logResponse.error) {
      console.error(logResponse.message);
    }
  }
  catch (ex) {
    console.error(ex.message);
  }
}

const instance = axios.create({

  baseURL: process.env.VUE_APP_API,
  withCredentials: true,
  headers: {
    apikey: getCookie('app_api_key') ?? '',
  },
});

instance.interceptors.request.use(config => {
  config.metadata = {startTime: new Date().getTime()};
  // override base url if mock is enabled and should work only for development
  if (config.useMock && process.env.VUE_APP_MOCK_API && process.env.NODE_ENV !== 'production') {
    config.baseURL = process.env.VUE_APP_MOCK_API;
    config.withCredentials = false;
  }

  return config;
}, error => {
  return Promise.reject(error);
});

instance.interceptors.response.use(response => {
  sendLogs(Vue.prototype.$ddLogger, {route: router.currentRoute, response, config: response.config, message: response?.statusText || 'server response success'});

  return response;
}, async error => {
  sendLogs(Vue.prototype.$ddLogger, {route: router.currentRoute, response: error.response, config: error.config, message: error?.message || 'client response error'});
  if (error.response && error.response.status === 401) {
    if (!error.config.url.endsWith('/api/login') &&
            !error.config.url.endsWith('/api/customers/me')) {
      console.warn('Logging out; your session has probably expired.');

      await store.dispatch('auth/logout', {silent: true});

      if (router.currentRoute.path !== '/login') {
        router.push({

          path: '/login',
          query: {redirect: router.currentRoute.fullPath},
        });
      }
    }
  }
  else if (error.response && error.response.status === 403) {
    router.replace('/403');
  }

  return Promise.reject(error);
});

export default instance;
