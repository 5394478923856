<template>

  <footer class="footer">
    <div class="container">
      <div class="row">

        <div class="col text-center">
          v{{versionJson.version}}. Copyright © {{getFullYear}} <a href="https://hellochef.me">Hello Chef</a>. All rights reserved.
        </div>

      </div>
    </div>
  </footer>

</template>

<script>
import versionJson from '@/version.json';

export default {
  name: 'AppFooter',
  data() {
    return {
      versionJson,
    };
  },
  computed: {
    getFullYear() {
      return new Date().getFullYear();
    },
  },
};
</script>
