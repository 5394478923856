import {checkPermissions} from '@hellochef/shared-js/helpers';
import Vue from 'vue';
import Router from 'vue-router';
import {sync} from 'vuex-router-sync';
import {customers, inventory, menu, operations, procurement, promotions, reports, tools, themes} from './routes';
import store from './store';

Vue.use(Router);

const router = new Router({
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/welcome',
    },
    {
      path: '/403',
      component: () => import('./errors/403.vue'),
      meta: {
        standalone: true,
        title: 'Forbidden',
      },
    },
    {
      path: '/login',
      component: () => import('./views/Login.vue'),
      meta: {
        guestsOnly: true,
        standalone: true,
      },
    },
    {
      name: 'home',
      path: '/welcome',
      component: () => import('./views/Welcome.vue'),
      meta: {
        requiresAuth: true,
        title: 'Welcome',
      },
    },
    {
      path: '/logout',
      beforeEnter: (to, from, next) => {
        store.dispatch('auth/logout').then(() => next('/login')).catch(() => next(false));
      },
    },
    ...reports,
    ...menu,
    ...customers,
    ...promotions,
    ...procurement,
    ...operations,
    ...inventory,
    ...tools,
    ...themes,
    {
      path: '/*',
      component: () => import('./errors/404.vue'),
      meta: {
        requiresAuth: true,
        standalone: true,
      },
    },
    /* eslint-enable sort-keys */
  ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.guestsOnly)) {
    if (store.getters['auth/isAuthenticated']) {
      store.dispatch('auth/logout').then(() => next()).catch(() => next(false));
    }
    else {
      // continue navigation - we're already logged out.
      next();
    }
  }
  else if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters['auth/isAuthenticated']) {
      const query = {};

      if (to.query.auth_error) {
        query.auth_error = to.query.auth_error;
        delete to.query.auth_error;
      }

      if (to.fullPath !== '/') {
        const redirect = to.path;

        if (Object.keys(to.query) > 0) {
          const queryString = Object.keys(to.query).map(key => `${key}=${to.query[key]}`).join('&');
          redirect.concat(`?${queryString}`);
        }

        query.redirect = redirect;
      }

      next({path: '/login', query: query});
    }
    else {
      const toPermissions = to.meta.permissions;

      // page does not have condition to check permission
      if (!toPermissions || to.name === 'home') {
        next();
        return;
      };

      // does the user has access to view this page?
      const canViewPage = checkPermissions((to.meta.permissions || {}).view, store.getters['auth/permissions']);
      if (!canViewPage) {
        next('/403');
        return;
      }

      // user has access to view the page
      next();
    }
  }
  else {
    next();
  }
});

sync(store, router);

export default router;
