import {StatsigClient} from '@statsig/js-client';

export default {
  async install(Vue) {
    if (process.env.VUE_APP_ENV === 'development') {
      return;
    }

    const myStatsigClient = new StatsigClient(
      process.env.VUE_APP_STATSIG_KEY,
      {userID: null},
      {environment: {tier: process.env.VUE_APP_ENV}},
    );

    await myStatsigClient.initializeSync();

    Vue.prototype.$statsig = myStatsigClient;
  },
};
