import {BrowserTracing} from '@sentry/tracing';
import * as Sentry from '@sentry/vue';

const IGNORE_LIST = [
  'Request failed with status code',
  'Error: Network Error',
  'Network Error',
  'Request aborted',
  'The object does not support the operation or argument.',
  'Request failed with status code 401',
  'Request failed with status code 404',
  'Request failed with status code 422',
  'Large Render Blocking Asset',
  'Load timeout for modules: core,bootstrap,jquery',
  '$ is not defined',
  '_.Af is not a function',
  '$(...).tooltip is not a function',
  'Unexpected token \'<\'',
];

export default {
  install(Vue) {
    Sentry.init({
      Vue,
      dsn: process.env.VUE_APP_SENTRY_DSN,
      environment: process.env.VUE_APP_ENV,
      logErrors: true,
      integrations: [new BrowserTracing()],
      tracesSampleRate: 0.2,
      tracingOptions: {
        trackComponents: true,
      },
      attachProps: true,
      attachStacktrace: true,
      ignoreErrors: IGNORE_LIST,
    });
  },
};
