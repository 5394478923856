<template>
  <b-modal
    v-if="releaseNotes"
    v-model="showModal"
    title="Release Notes"
    centered
    hide-footer
    @hide="onClose">
    <template slot="modal-header-close"><wbr/></template>
    <div class="container img-container release-modal-container">
      <div class="row">
        <div class="col-12 d-flex flex-column gap-20">
          <samp class="text-center">
            {{versionJson.version}} | {{versionJson.date}}
          </samp>

          <h1 class="text-center mb-2">
            Fresh out the oven 🔥
          </h1>

          <div>
            <ul>
              <li
                v-for="item in releaseNotes.release_notes"
                :key="item"
                class="li-point">
                <span v-html="item" class="blockquote"/>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import versionJson from '@/version.json';
import {mapGetters} from 'vuex';

export default {
  name: 'ReleaseModal',
  props: {
    value: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      versionJson,
      showModal: false,
      releaseNotes: null,
    };
  },
  computed: {
    ...mapGetters('auth', ['roles']),
  },
  mounted() {
    this.releaseNotes = this.$statsig?.getDynamicConfig('admin-release-notes')?.value;
    if (!this.roles.length || !this.releaseNotes) return;

    const {release_notes, roles, version} = this.releaseNotes || {};
    if (!release_notes || !roles || !version) return;

    const localVersion = localStorage.getItem('version');
    const hasReleaseNotes = release_notes.length > 0;
    const hasMatchingRole = this.roles.includes('Administrator') || roles.length === 0 || roles.some(role => this.roles.includes(role));

    this.showModal =
      localVersion !== this.versionJson.version && // Check if the user has seen the release notes
      this.versionJson.version === version && // Check if new its a new version
      hasReleaseNotes && hasMatchingRole;
  },
  methods: {
    onClose() {
      if (this.showModal) {
        localStorage.setItem('version', this.versionJson.version);
      }
    },
  },
};
</script>

<style>
.release-modal-container .li-point {
  margin-bottom: 4px;
  line-height: 24px;
}
</style>
