<template>
  <div class="page" id="app">
    <template v-if="!standalone">
      <div class="page-main">
        <app-header></app-header>
        <div class="my-3 my-md-5">
          <router-view/>
        </div>
      </div>
      <app-footer></app-footer>
      <release-modal/>
    </template>
    <router-view v-else></router-view>
  </div>
</template>

<script>
import AppFooter from './components/AppFooter.vue';
import AppHeader from './components/AppHeader.vue';
import ReleaseModal from './components/modals/ReleaseModal.vue';

const ENVS = {
  salt: 'salt',
  pepper: 'pepper',
};
const SALT_COLOR = '#ECF9FF';
const PEPPER_COLOR = '#F2F9F1';

export default {
  components: {
    AppFooter,
    AppHeader,
    ReleaseModal,
  },
  computed: {
    standalone() {
      return this.$route.meta.standalone;
    },
  },
  mounted() {
    const currentEnv = process.env.VUE_APP_ENV;
    const isExists = Object.keys(ENVS).includes(currentEnv);
    if (isExists) {
      const color = currentEnv === ENVS.salt ? SALT_COLOR : PEPPER_COLOR;
      document.body.style.backgroundColor = color;
    }
  },
};
</script>

<style>
#nprogress .bar {
  background: #467fcf;
}

#nprogress .peg {
  display: none;
}
</style>
