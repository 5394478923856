import Permissions from '@hellochef/shared-js/enums/Permissions';

export default [
  {
    path: '/themes',
    component: () => import('@/views/themes/Index.vue'),
    meta: {
      name: 'themes',
      requiresAuth: true,
      permissions: {
        view: Permissions.THEMES_VIEW,
        create: Permissions.THEMES_CREATE,
        update: Permissions.THEMES_UPDATE,
        delete: Permissions.THEMES_DELETE,
      },
    },
  },
  {
    path: '/themes/:id',
    component: () => import('@/views/themes/Edit.vue'),
    meta: {
      name: 'themes',
      requiresAuth: true,
      search: '/themes',
      permissions: {
        view: Permissions.THEMES_VIEW,
        create: Permissions.THEMES_CREATE,
        update: Permissions.THEMES_UPDATE,
        delete: Permissions.THEMES_DELETE,
      },
    },
  },
];
