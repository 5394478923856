import Permissions from '@hellochef/shared-js/enums/Permissions';

export default [
  {
    path: '/inventory/shopping',
    component: () => import('@/views/inventory/shopping/Index.vue'),
    meta: {
      name: 'shoppingCarts',
      requiresAuth: true,
      permissions: {
        view: Permissions.INTERNAL_PURCHASE_VIEW,
        create: false,
        update: false,
        delete: false,
      },
    },
  },
  {
    path: '/inventory/shopping/:id',
    component: () => import('@/views/inventory/shopping/Edit.vue'),
    meta: {
      name: 'shoppingCarts',
      requiresAuth: true,
      permissions: {
        view: Permissions.INTERNAL_PURCHASE_VIEW,
        create: false,
        update: Permissions.INTERNAL_PURCHASE_PURCHASE_ORDER_CREATE,
        delete: false,
      },
      search: '/inventory/shopping',
    },
  },
  {
    path: '/inventory/stocks/:routeName',
    component: () => import('@/views/inventory/stocks/Index.vue'),
    meta: {
      name: 'stockOnHand',
      requiresAuth: true,
      permissions: {
        view: Permissions.STOCK_ON_HAND_VIEW,
        create: false,
        update: false,
        delete: false,
      },
    },
    props: true,
  },
  {
    path: '/inventory/reports/stocks/:routeName',
    component: () => import('@/views/inventory/reports/stocks/Index.vue'),
    meta: {
      name: 'stockOnHand',
      requiresAuth: true,
      permissions: {
        view: Permissions.STOCK_ON_HAND_VIEW,
        create: false,
        update: false,
        delete: false,
      },
    },
    props: true,
  },
];
