import {track} from './util';

export const trackers = () => {
  /**
   * @todo As this file grows, split these events into separate files.
   * @todo These new files need to be included in assets/segment/index.js.
   */
  return {
    'Admin Update Subscription': (event, customer, {
      changes,
      subscription,
    }) => {
      if (changes.product || changes.itemStatus) {
        track(event, {
          subscriptionId: subscription.id,
          product: changes.product,
          itemStatus: changes.itemStatus,
        });
      }

      if (changes.mealPlan || changes.mealPreference) {
        track('Admin Update Preferences', {
          subscriptionId: subscription.id,
          mealPlan: changes.mealPlan,
          mealPreference: changes.mealPreference,
        });
      }

      if (changes.deliveryDay || changes.deliveryTime) {
        track('Admin Update Delivery Details', {
          subscriptionId: subscription.id,
          deliveryDay: changes.deliveryDay,
          deliveryTime: changes.deliveryTime,
        });
      }

      if (changes.skipped) {
        for (const [week, skipped] of Object.entries(changes.skipped)) {
          track('Admin Update Skipping', {
            subscriptionId: subscription.id,
            week,
            skipped,
          });
        }
      }

      if (changes.selections) {
        for (const [week, selections] of Object.entries(changes.selections)) {
          track('Admin Update Recipe Selection', {
            subscriptionId: subscription.id,
            week,
            selections,
          });
        }
      }
    },

    'Admin Update User Details': (event, customer, {
      changes,
    }) => {
      if (changes.billingAddress) {
        track('Admin Update Billing Address');
      }

      if (changes.deliveryAddress) {
        track('Admin Update Delivery Address');
      }

      if (changes.user.length > 0) {
        track(event, {
          fieldsChanged: changes.user,
        });
      }
    },
  };
};
