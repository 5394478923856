import Alert from '@/components/Alert';
import Dimmer from '@/components/Dimmer';
import pageMeta from '@/mixins/pageMeta';
import Segment from '@dansmaculotte/vue-segment';
import SimpleBodyValidator from '@hellochef/shared-js/plugins/SimpleBodyValidator';
import VueHelpers from '@hellochef/shared-js/plugins/VueHelpers';
import VueRoles from '@hellochef/shared-js/plugins/VueRoles';
import {loadProgressBar} from 'axios-progress-bar';
import 'axios-progress-bar/dist/nprogress.css';
import {BDropdown, BDropdownDivider, BDropdownGroup, BDropdownItemButton, BModal, BootstrapVueIcons, TooltipPlugin} from 'bootstrap-vue';
import {capitalize, lowerCase, startCase} from 'lodash';
import vClickOutside from 'v-click-outside';
import Vue from 'vue';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import VueSvgInlinePlugin from 'vue-svg-inline-plugin';
import Toasted from 'vue-toasted';
import Selectize from 'vue2-selectize';
import Vuelidate from 'vuelidate';
import App from './App.vue';
import Modal from './components/Modal';
import DatadogLogger from './plugins/DatadogLogger';
import Sentry from './plugins/Sentry';
import VueCloudinary from './plugins/VueCloudinary';
import router from './router';
import $axios from './services/axios';
import store from './store';
import './styles/app.css';
import Statsig from './plugins/Statsig';

Vue.mixin(pageMeta);

Vue.config.productionTip = false;
Vue.prototype.capitalize = capitalize;
Vue.prototype.lowerCase = lowerCase;
Vue.prototype.startCase = startCase;

Vue.use(Sentry);
Vue.use(DatadogLogger);
Vue.use(VueCloudinary);
Vue.use(VueHelpers);
Vue.use(VueRoles);
Vue.use(SimpleBodyValidator);
Vue.use(Toasted, {
  position: 'top-center',
  duration: 3000,
});
Vue.use(Vuelidate);
Vue.use(TooltipPlugin);
Vue.use(BootstrapVueIcons);
Vue.use(Segment, {
  disabled: process.env.NODE_ENV !== 'production',
  writeKey: process.env.VUE_APP_SEGMENT_KEY,
});
Vue.use(vClickOutside);
Vue.use(Statsig);

Vue.component('alert', Alert);
Vue.component('b-modal', BModal);
Vue.component('b-dropdown', BDropdown);
Vue.component('b-dropdown-divider', BDropdownDivider);
Vue.component('b-dropdown-group', BDropdownGroup);
Vue.component('b-dropdown-item-button', BDropdownItemButton);
Vue.component('modal', Modal);
Vue.component('dimmer', Dimmer);
Vue.component('selectize', Selectize);
Vue.component('v-select', vSelect);

VueSvgInlinePlugin.install(Vue, {
  attributes: {
    data: ['src'],
    remove: ['alt'],
  },
});

Vue.directive('disable-all', {

  // When all the children of the parent component have been updated
  componentUpdated: function(el, binding) {
    const tags = ['input', 'button', 'textarea', 'select'];

    tags.forEach(tagName => {
      const nodes = el.getElementsByTagName(tagName);

      for (let i = 0; i < nodes.length; i++) {
        if (!binding.value || binding.modifiers?.ignore || nodes[i].hasAttribute('ignore-disable')) {
          if (!nodes[i].hasAttribute('force-disable')) {
            nodes[i].removeAttribute('disabled');
          }
        }
        else {
          nodes[i].disabled = true;
          nodes[i].tabIndex = -1;
        }
      }
    });
  },
});

loadProgressBar({

  showSpinner: false,
},
$axios);

store.dispatch('auth/restore').then(() => {
  const app = new Vue({

    render: h => h(App),
    router,
    store,
  });

  router.onReady(() => app.$mount('#app'));
});

/**
 * This block of code will require all the js & vue files
 * from the `src` folder.
 *
 * This allows Babel to instrument all the files, and not just
 * the files that are imported or required at runtime.
 *
 * This is needed to calculate true code coverage
 * while running E2E tests with Cypress.
 */
if (process.env.NODE_ENV === 'staging') {
  const req = require.context('./', true, /\.(js|vue)$/i);
  req.keys().map(key => {
    const name = key.match(/\w+/)[0];
    return Vue.component(name, req(key));
  });
}
