import {uom} from '@/services';

export const SET_UOMS = 'SET_UOMS';

export default {

  actions: {

    async getUoms({commit, state}) {
      if (!state.uoms.length) {
        const uoms = (await uom.getByParameters()).unitOfMeasures;
        commit(SET_UOMS, uoms);
      }
    },
  },
  mutations: {

    [SET_UOMS](state, value) {
      state.uoms = value;
    },
  },
  namespaced: true,
  state: {
    uoms: [],
  },
};
