import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth';
import events from './modules/events';
import uom from './modules/uom';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    events,
    uom,
  },
  strict: true,
});
