/**
 * @todo As the number of custom events grows, we will split them into multiple files
 * @todo Import all of them here so they can be added to the `_eventMap`.
 */
import {trackers as customTrackers} from './custom';
import {identify, reset, track} from './util';

const _eventMap = {
  // List of all the custom handlers
  ...customTrackers(),

  // The default behavior is to track the event
  default: track,
};

/**
 * Returns the list of events (by name) that do not have to be tracked
 */
export const _ignoreList = [
  // List of event names goes here
];

/**
 * All trackers must have a `trackEvent` method
 * @param {*} event
 * @param {*} properties
 */
export const trackEvent = (event, customer, properties) => {
  // Identify the customer on behalf of whom this action is being taken.
  if (customer) {
    identify(customer);
  }

  if (typeof _eventMap[event] === 'function') {
    /**
     * Some events like login will have custom handlers.
     * Create a custom handler if you want to:
     * 1. Do something extra with the event, such as calling the `identify` method.
     * 2. Modify the properties or event name before sending it to the tracker.
     *
     * `track` method will not be automatically called for custom handlers.
     */
    _eventMap[event](event, customer, properties);
  }
  else {
    /**
     * Track this event
     */
    _eventMap.default(event, properties);
  }

  // Reset the session to prevent tagging random events against a customer.
  reset();
};
