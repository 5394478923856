<template>

  <div class="dimmer" :class="{active}">
    <template v-if="!hideLoader">
      <div class="loader"></div>
    </template>

    <slot name="custom"></slot>
    <div class="dimmer-content">
      <slot></slot>
    </div>
  </div>

</template>

<script>

export default {

  props: [

    'active',
    'hideLoader',
  ],

};

</script>
