import Permissions from '@hellochef/shared-js/enums/Permissions';

export default [
  {
    path: '/coupons',
    component: () => import('@/views/coupons/Index.vue'),
    meta: {
      name: 'coupons',
      requiresAuth: true,
      permissions: {
        view: Permissions.COUPONS_VIEW,
        create: Permissions.COUPONS_CREATE,
        update: Permissions.COUPONS_UPDATE,
        delete: Permissions.COUPONS_DELETE,
      },
    },
  },
  {
    path: '/coupons/:id',
    component: () => import('@/views/coupons/Edit.vue'),
    meta: {
      name: 'coupons',
      requiresAuth: true,
      search: '/coupons',
      permissions: {
        view: Permissions.COUPONS_VIEW,
        create: Permissions.COUPONS_CREATE,
        update: Permissions.COUPONS_UPDATE,
        delete: Permissions.COUPONS_DELETE,
      },
    },
  },
  {
    path: '/store-credit',
    component: () => import('@/views/storeCredit/Index.vue'),
    meta: {
      name: 'storeCredits',
      requiresAuth: true,
      permissions: {
        view: Permissions.STORE_CREDIT_VIEW,
        create: Permissions.STORE_CREDIT_CREATE,
        update: Permissions.STORE_CREDIT_UPDATE,
        delete: Permissions.STORE_CREDIT_DELETE,
      },
    },
  },
  {
    path: '/store-credit/:id',
    component: () => import('@/views/storeCredit/Edit.vue'),
    meta: {
      name: 'storeCredits',
      requiresAuth: true,
      search: '/store-credit',
      permissions: {
        view: Permissions.STORE_CREDIT_VIEW,
        create: Permissions.STORE_CREDIT_CREATE,
        update: Permissions.STORE_CREDIT_UPDATE,
        delete: Permissions.STORE_CREDIT_DELETE,
      },
    },
  },
  {
    path: '/discounts',
    component: () => import('@/views/discounts/Index.vue'),
    meta: {
      name: 'discounts',
      requiresAuth: true,
      permissions: {
        view: Permissions.DISCOUNTS_VIEW,
        create: Permissions.DISCOUNTS_CREATE,
        update: Permissions.DISCOUNTS_UPDATE,
        delete: Permissions.DISCOUNTS_DELETE,
      },
    },
  },
  {
    path: '/discounts/:id',
    component: () => import('@/views/discounts/Edit.vue'),
    meta: {
      name: 'discounts',
      requiresAuth: true,
      search: '/discounts',
      permissions: {
        view: Permissions.DISCOUNTS_VIEW,
        create: Permissions.DISCOUNTS_CREATE,
        update: Permissions.DISCOUNTS_UPDATE,
        delete: Permissions.DISCOUNTS_DELETE,
      },
    },
  },
  {
    path: '/referrals',
    component: () => import('@/views/referrals/Index.vue'),
    meta: {
      name: 'referrals',
      requiresAuth: true,
      permissions: {
        view: Permissions.REFERRAL_VIEW,
        create: Permissions.REFERRAL_CREATE,
        update: false,
        delete: false,
      },
    },
  },
  {
    path: '/referrals/:id',
    component: () => import('@/views/referrals/Edit.vue'),
    meta: {
      name: 'referrals',
      requiresAuth: true,
      search: '/referrals',
      permissions: {
        view: Permissions.REFERRAL_VIEW,
        create: Permissions.REFERRAL_CREATE,
        update: false,
        delete: false,
      },
    },
  },
];
