import {auth as authService} from '@/services';
import $axios from '@/services/axios';
import $axiosMl from '@/services/axios-ml';
import {deleteCookie, setCookie} from '@hellochef/shared-js/helpers';
import * as Sentry from '@sentry/browser';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const SET_NOTIFICATION = 'SET_NOTIFICATION';

export default {
  actions: {
    async login({commit}, {customer, api_key}) {
      if (api_key?.key && api_key?.ttl) {
        const host = location.hostname;
        const domain = host.substring(host.indexOf('.'));
        setCookie('app_api_key', api_key.key, api_key.ttl, domain);
        // update the apikey in the axios instance
        Object.assign($axios.defaults.headers, {apikey: api_key.key});
        Object.assign($axiosMl.defaults.headers, {apikey: api_key.key});
      }

      if (!customer) { customer = (await authService.me()).item; }

      Sentry.configureScope(scope => {
        scope.setUser({

          email: customer.user.email,
          id: customer.id,
        });
      });

      commit(LOGIN, {customer});
    },
    async logout({commit}, {silent} = {}) {
      if (!silent) { await authService.logout(); }

      Sentry.configureScope(scope => scope.setUser(null));

      commit(LOGOUT);
    },
    async restore({dispatch}) {
      try {
        await dispatch('login', {});
      }
      catch (ex) {
        if (ex.response && ex.response.status === 401) {
          console.error('Unable to restore your session.');
        }
        else {
          console.error(ex);
        }
      }
    },
  },
  getters: {
    isAuthenticated(state) {
      return !!state.customer;
    },
    legacyRoles(state) {
      return state.customer ? state.customer.user.legacy_roles : [];
    },
    roles(state) {
      return state.customer ? state.customer.user.roles : [];
    },
    permissions(state) {
      return state.customer ? state.customer.user.permissions : [];
    },
  },
  mutations: {
    [LOGIN](state, {customer}) {
      state.customer = customer;
    },
    [LOGOUT](state) {
      state.customer = null;
      const host = location.hostname;
      const domain = host.substring(host.indexOf('.'));
      deleteCookie('app_api_key', domain);
    },
  },
  namespaced: true,
  state: {
    customer: null,
  },
};
