<template>
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M12.5 8.5H10.17L12 6.73C12.2809 6.44875 12.4387 6.0675 12.4387 5.67C12.4387 5.2725 12.2809 4.89125 12 4.61L9.89 2.5C9.60875 2.2191 9.2275 2.06132 8.83 2.06132C8.4325 2.06132 8.05125 2.2191 7.77 2.5L6 4.255V2C6 1.60218 5.84196 1.22064 5.56066 0.93934C5.27936 0.658035 4.89782 0.5 4.5 0.5H1.5C1.10218 0.5 0.720644 0.658035 0.43934 0.93934C0.158035 1.22064 0 1.60218 0 2V11.5C0 12.2956 0.31607 13.0587 0.87868 13.6213C1.44129 14.1839 2.20435 14.5 3 14.5H12.5C12.8978 14.5 13.2794 14.342 13.5607 14.0607C13.842 13.7794 14 13.3978 14 13V10C14 9.60218 13.842 9.22064 13.5607 8.93934C13.2794 8.65804 12.8978 8.5 12.5 8.5ZM8.5 3.195C8.59368 3.10187 8.72041 3.0496 8.8525 3.0496C8.98459 3.0496 9.11132 3.10187 9.205 3.195L11.33 5.32C11.4216 5.41346 11.4729 5.53912 11.4729 5.67C11.4729 5.80088 11.4216 5.92654 11.33 6.02L6 11.125V5.67L8.5 3.195ZM1 11.5V2C1 1.86739 1.05268 1.74021 1.14645 1.64645C1.24021 1.55268 1.36739 1.5 1.5 1.5H4.5C4.63261 1.5 4.75979 1.55268 4.85355 1.64645C4.94732 1.74021 5 1.86739 5 2V11.5C5 12.0304 4.78929 12.5391 4.41421 12.9142C4.03914 13.2893 3.53043 13.5 3 13.5C2.46957 13.5 1.96086 13.2893 1.58579 12.9142C1.21071 12.5391 1 12.0304 1 11.5ZM13 13C13 13.1326 12.9473 13.2598 12.8536 13.3536C12.7598 13.4473 12.6326 13.5 12.5 13.5H5.23C5.417 13.2904 5.57508 13.0566 5.7 12.805L9.135 9.5H12.5C12.6326 9.5 12.7598 9.55268 12.8536 9.64645C12.9473 9.74021 13 9.86739 13 10V13Z" :fill="color"/>
    <path d="M3 12.5C3.55228 12.5 4 12.0523 4 11.5C4 10.9477 3.55228 10.5 3 10.5C2.44772 10.5 2 10.9477 2 11.5C2 12.0523 2.44772 12.5 3 12.5Z" :fill="color"/>
    <path d="M2.5 5.5C2.63261 5.5 2.75979 5.44732 2.85355 5.35355C2.94732 5.25979 3 5.13261 3 5V3C3 2.86739 2.94732 2.74021 2.85355 2.64645C2.75979 2.55268 2.63261 2.5 2.5 2.5C2.36739 2.5 2.24021 2.55268 2.14645 2.64645C2.05268 2.74021 2 2.86739 2 3V5C2 5.13261 2.05268 5.25979 2.14645 5.35355C2.24021 5.44732 2.36739 5.5 2.5 5.5Z" :fill="color"/>
  </svg>
</template>

<script>
export default {
  name: 'IconTheme',
  props: {
    color: {
      type: String,
      default: '#9aa0ac',
    },
  },
};
</script>
