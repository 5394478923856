import axios from 'axios';
import {getCookie} from '@hellochef/shared-js/helpers';

export default axios.create({
  baseURL: process.env.VUE_APP_ML_API,
  withCredentials: true,
  headers: {
    apikey: getCookie('app_api_key') ?? '',
  },
});
