import Permissions from '@hellochef/shared-js/enums/Permissions';

export default [
  {
    path: '/recipes',
    component: () => import('@/views/recipes/Index.vue'),
    meta: {
      name: 'recipes',
      requiresAuth: true,
      hideSearch: true,
      permissions: {
        view: Permissions.RECIPES_VIEW,
        create: Permissions.RECIPES_CREATE,
        update: Permissions.RECIPES_UPDATE,
        delete: Permissions.RECIPES_DELETE,
      },
    },
  },
  {
    path: '/recipes/:id',
    component: () => import('@/views/recipes/Edit.vue'),
    meta: {
      name: 'recipes',
      requiresAuth: true,
      search: '/recipes',
      permissions: {
        view: Permissions.RECIPES_VIEW,
        create: Permissions.RECIPES_CREATE,
        update: Permissions.RECIPES_UPDATE,
        delete: Permissions.RECIPES_DELETE,
      },
    },
  },
  {
    path: '/customer-profiles',
    component: () => import('@/views/customerProfiles/Index.vue'),
    meta: {
      name: 'customerProfiles',
      requiresAuth: true,
      permissions: {
        view: Permissions.CUSTOMER_PROFILES_VIEW,
        create: false,
        update: false,
        delete: false,
      },
    },
  },
  {
    path: '/customer-profiles/:id',
    component: () => import('@/views/customerProfiles/Edit.vue'),
    meta: {
      name: 'customerProfiles',
      requiresAuth: true,
      permissions: {
        view: Permissions.CUSTOMER_PROFILES_VIEW,
        create: Permissions.CUSTOMER_PROFILES_CREATE,
        update: Permissions.CUSTOMER_PROFILES_UPDATE,
        delete: false,
      },
    },
  },
  {
    path: '/schedule-menu',
    component: () => import('@/views/scheduleMenu/Index.vue'),
    meta: {
      name: 'scheduleMenu',
      requiresAuth: true,
      permissions: {
        view: Permissions.MENU_SCHEDULE_VIEW,
        create: false,
        update: false,
        delete: false,
      },
    },
  },
  {
    path: '/schedule-market',
    component: () => import('@/views/scheduleMarket/Index.vue'),
    meta: {
      name: 'scheduleMarket',
      requiresAuth: true,
      permissions: {
        view: Permissions.MENU_SCHEDULE_VIEW,
        create: false,
        update: false,
        delete: false,
      },
    },
  },
  {
    path: '/weekly-menus',
    component: () => import('@/views/weeklyMenus/Index.vue'),
    meta: {
      name: 'weeklyMenus',
      requiresAuth: true,
      permissions: {
        view: Permissions.MENU_VIEW,
        create: false,
        update: Permissions.MENU_SCHEDULE_UPDATE,
        delete: false,
      },
    },
  },
  {
    path: '/weekly-menus/:id',
    component: () => import('@/views/weeklyMenus/Edit.vue'),
    meta: {
      name: 'weeklyMenus',
      requiresAuth: true,
      search: '/weekly-menus',
      permissions: {
        view: Permissions.MENU_VIEW,
        create: false,
        update: Permissions.MENU_SCHEDULE_UPDATE,
        delete: false,
      },
    },
  },
  {
    path: '/ingredients',
    component: () => import('@/views/ingredients/Index.vue'),
    meta: {
      name: 'ingredients',
      requiresAuth: true,
      permissions: {
        view: Permissions.INGREDIENTS_VIEW,
        create: Permissions.INGREDIENTS_CREATE,
        update: Permissions.INGREDIENTS_UPDATE,
        delete: false,
      },
    },
  },
  {
    path: '/ingredients/:id',
    component: () => import('@/views/ingredients/Edit.vue'),
    meta: {
      name: 'ingredients',
      requiresAuth: true,
      search: '/ingredients',
      permissions: {
        view: Permissions.INGREDIENTS_VIEW,
        create: Permissions.INGREDIENTS_CREATE,
        update: Permissions.INGREDIENTS_UPDATE,
        delete: false,
      },
    },
  },
  {
    path: '/tags',
    component: () => import('@/views/tags/Index.vue'),
    meta: {
      name: 'tags',
      requiresAuth: true,
      permissions: {
        view: Permissions.TAGS_VIEW,
        create: Permissions.TAGS_CREATE,
        update: Permissions.TAGS_UPDATE,
        delete: false,
      },
    },
  },
  {
    path: '/tags/:id',
    component: () => import('@/views/tags/Edit.vue'),
    meta: {
      name: 'tags',
      requiresAuth: true,
      search: '/tags',
      permissions: {
        view: Permissions.TAGS_VIEW,
        create: Permissions.TAGS_CREATE,
        update: Permissions.TAGS_UPDATE,
        delete: false,
      },
    },
  },
  {
    path: '/article-requisitions',
    component: () => import('@/views/articleRequisitions/Index.vue'),
    meta: {
      name: 'articleRequisitions',
      requiresAuth: true,
      permissions: {
        view: Permissions.ARTICLES_REQUISITIONS_VIEW,
        create: Permissions.ARTICLES_REQUISITIONS_CREATE,
        update: Permissions.ARTICLES_REQUISITIONS_UPDATE,
        delete: Permissions.ARTICLES_REQUISITIONS_DELETE,
      },
    },
  },
  {
    path: '/article-requisitions/:id',
    component: () => import('@/views/articleRequisitions/Edit.vue'),
    meta: {
      name: 'articleRequisitions',
      requiresAuth: true,
      search: '/article-requisitions',
      permissions: {
        view: Permissions.ARTICLES_REQUISITIONS_VIEW,
        create: Permissions.ARTICLES_REQUISITIONS_CREATE,
        update: Permissions.ARTICLES_REQUISITIONS_UPDATE,
        delete: Permissions.ARTICLES_REQUISITIONS_DELETE,
      },
    },
  },
];
