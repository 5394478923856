export const track = (event, payload) => {
  window.analytics.track(event, payload);
};

export const page = (name, payload) => {
  window.analytics.page(name, payload);
};

export const identify = customer => {
  window.analytics.identify(customer.id, {
    email: customer.user.email,
  });
};

export const reset = () => {
  window.analytics.reset();
};
