<template>
  <header>
    <div class="header py-4">
      <div class="container">
        <div class="d-flex">
          <router-link class="header-brand" to="/">
            <img
              src="@/assets/logo.png"
              class="header-brand-img"
              alt="logo">
          </router-link>
          <div
            v-if="ENV"
            class="d-flex align-items-center text-capitalize h3 m-0 ml-auto"
          >
            <img
              :src="ENV_ICON"
              class="header-brand-img"
              alt="evn logo">
            {{ENV}}
          </div>
          <div class="d-flex order-lg-2 ml-auto">
            <div class="dropdown d-none d-md-flex">
              <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow notifications" style="width: 600px;">
                <div class="d-flex align-items-center justify-content-center">
                  You have no notifications.
                </div>
              </div>
            </div>
            <div class="dropdown">
              <div
                tabindex="0"
                class="dropdown-toggle d-flex align-items-center pr-0 leading-none"
                data-toggle="dropdown">
                <span class="avatar" :style="`background-image: url(${user ? identicon(user.email) : ''});`"></span>
                <span data-test="btn-logged-in-account" class="ml-2 d-none d-lg-block text-truncate w-9">
                  <span data-test="label-user-fname" class="text-default">{{(user || {}).firstName}}</span>
                  <small
                    v-if="(user || {}).lastName"
                    data-test="label-user-lname"
                    class="text-muted d-block mt-1 text-truncate">{{(user || {}).lastName}}</small>
                </span>
              </div>
              <div data-test="btn-logout" class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                <router-link class="dropdown-item" to="/logout">
                  <i class="dropdown-icon fe fe-log-out"></i> Sign out
                </router-link>
              </div>
            </div>
          </div>
          <a
            tabindex="0"
            class="header-toggler d-lg-none ml-3 ml-lg-0"
            data-toggle="collapse"
            data-target="#headerMenuCollapse">
            <span class="header-toggler-icon"></span>
          </a>
        </div>
      </div>
    </div>
    <div class="header collapse d-lg-flex p-0" id="headerMenuCollapse">
      <div class="container">
        <div class="row align-items-center">
          <div v-if="!$route.meta.hideSearch" class="col-lg-3 ml-auto">
            <form class="input-icon my-3 my-lg-0" @submit.prevent="search">
              <input
                v-model="query"
                type="search"
                class="form-control header-search"
                placeholder="Search"
                tabindex="1">
              <div class="input-icon-addon">
                <i class="fe fe-search"></i>
              </div>
            </form>
          </div>
          <div class="col-lg order-lg-first">
            <ul class="nav nav-tabs border-0 flex-column flex-lg-row">
              <li v-if="can(uiPermissions.REPORTS_RECIPES_VIEW)" class="nav-item">
                <router-link
                  :to="'/reports/recipes'"
                  data-test="link-reports"
                  class="nav-link">
                  <i class="fe fe-pie-chart"></i>
                  Reports
                </router-link>
              </li>
              <li
                v-if="can([uiPermissions.RECIPES_VIEW, uiPermissions.CUSTOMER_PROFILES_VIEW, uiPermissions.MENU_SCHEDULE_VIEW, uiPermissions.MENU_VIEW, uiPermissions.INGREDIENTS_VIEW, uiPermissions.TAGS_VIEW, uiPermissions.ARTICLES_REQUISITIONS_VIEW, uiPermissions.THEMES_VIEW])"
                class="nav-item dropdown-toggle">
                <a
                  href="javascript:void(0)"
                  class="nav-link"
                  data-test="link-menu-planning"
                  :class="{
                    'active': ['recipes', 'customerProfiles', 'scheduleMenu', 'scheduleMarket', 'weeklyMenus', 'ingredients', 'tags', 'articleRequisitions', 'themes'].includes($route.meta.name)
                  }"
                  data-toggle="dropdown">
                  <i class="fe fe-calendar"></i> Menu planning
                </a>
                <div class="dropdown-menu dropdown-menu-arrow dropright">
                  <router-link
                    v-if="can(uiPermissions.RECIPES_VIEW)"
                    to="/recipes"
                    data-test="link-recipes"
                    class="dropdown-item">
                    <i class="dropdown-icon fe fe-list"></i> Recipes
                  </router-link>
                  <router-link
                    v-if="can(uiPermissions.CUSTOMER_PROFILES_VIEW)"
                    to="/customer-profiles"
                    data-test="link-customer-profiles"
                    class="dropdown-item">
                    <i class="dropdown-icon fe fe-users"></i> Customer profiles
                  </router-link>
                  <router-link
                    v-if="can(uiPermissions.MENU_SCHEDULE_VIEW)"
                    to="/schedule-menu"
                    data-test="link-scheduling-tool"
                    class="dropdown-item">
                    <i class="dropdown-icon fe fe-book"></i> Scheduling tool
                  </router-link>
                  <router-link
                    v-if="can(uiPermissions.MENU_SCHEDULE_VIEW)"
                    to="/schedule-market"
                    data-test="link-schedule-market"
                    class="dropdown-item">
                    <i class="dropdown-icon fe fe-shopping-bag"></i> Schedule market
                  </router-link>
                  <router-link
                    v-if="can(uiPermissions.MENU_VIEW)"
                    to="/weekly-menus"
                    data-test="link-weekly-menus"
                    class="dropdown-item">
                    <i class="dropdown-icon fe fe-calendar"></i> Weekly menus
                  </router-link>
                  <router-link
                    v-if="can(uiPermissions.INGREDIENTS_VIEW)"
                    to="/ingredients"
                    data-test="link-ingredients"
                    class="dropdown-item">
                    <i class="dropdown-icon fe fe-feather"></i> Ingredients
                  </router-link>
                  <router-link
                    v-if="can(uiPermissions.TAGS_VIEW)"
                    to="/tags"
                    data-test="link-tags"
                    class="dropdown-item">
                    <i class="dropdown-icon fe fe-tag"></i> Tags
                  </router-link>
                  <router-link
                    v-if="can(uiPermissions.THEMES_VIEW)"
                    to="/themes"
                    data-test="link-themes"
                    class="dropdown-item">
                    <IconTheme class="dropdown-icon theme-icon"/>Themes
                  </router-link>
                  <router-link
                    v-if="can(uiPermissions.ARTICLES_REQUISITIONS_VIEW)"
                    to="/article-requisitions"
                    data-test="link-article-requisitions"
                    class="dropdown-item">
                    <i class="dropdown-icon fe fe-paperclip"></i> Article Requisitions
                  </router-link>
                </div>
              </li>
              <li
                v-if="can([uiPermissions.CUSTOMERS_VIEW, uiPermissions.ROLES_AND_PERMISSIONS_VIEW, uiPermissions.SUBSCRIPTIONS_VIEW, uiPermissions.ORDERS_VIEW, uiPermissions.ORDER_CHANGE_REQUESTS_VIEW])"
                class="nav-item dropdown-toggle">
                <a
                  href="javascript:void(0)"
                  data-test="link-customers"
                  class="nav-link"
                  data-toggle="dropdown"
                  :class="{
                    'active': ['users', 'rolesAndPermissions', 'subscriptions', 'orders', 'order-change-requests'].includes($route.meta.name)
                  }">
                  <i class="fe fe-thumbs-up"></i> Customers
                </a>
                <div class="dropdown-menu dropdown-menu-arrow dropright">
                  <router-link
                    v-if="can(uiPermissions.CUSTOMERS_VIEW)"
                    to="/users"
                    data-test="link-users"
                    class="dropdown-item">
                    <i class="dropdown-icon fe fe-users"></i> Users
                  </router-link>
                  <router-link
                    v-if="can(uiPermissions.SUPER_ADMIN)"
                    to="/roles-permissions"
                    data-test="link-roles-permissions"
                    class="dropdown-item">
                    <i class="dropdown-icon fe fe-shield"></i> Roles & Permissions
                  </router-link>
                  <router-link
                    v-if="can(uiPermissions.SUBSCRIPTIONS_VIEW)"
                    to="/subscriptions"
                    data-test="link-subscriptions"
                    class="dropdown-item">
                    <i class="dropdown-icon fe fe-rss"></i> Subscriptions
                  </router-link>
                  <router-link
                    v-if="can(uiPermissions.ORDERS_VIEW)"
                    to="/orders"
                    data-test="link-orders"
                    class="dropdown-item">
                    <i class="dropdown-icon fe fe-package"></i> Orders
                  </router-link>
                  <router-link
                    v-if="can(uiPermissions.ORDER_CHANGE_REQUESTS_VIEW)"
                    to="/order-change-requests"
                    data-test="link-orders"
                    class="dropdown-item">
                    <i class="dropdown-icon fe fe-align-left"></i> Order change requests
                  </router-link>
                </div>
              </li>
              <li
                v-if="can([uiPermissions.COUPONS_VIEW, uiPermissions.STORE_CREDIT_VIEW, uiPermissions.DISCOUNTS_VIEW, uiPermissions.REFERRAL_VIEW])"
                class="nav-item dropdown-toggle">
                <a
                  href="javascript:void(0)"
                  class="nav-link"
                  data-test="link-promotions"
                  data-toggle="dropdown"
                  :class="{
                    'active': ['coupons', 'storeCredits', 'discounts', 'referrals'].includes($route.meta.name)
                  }">
                  <i class="fe fe-tag"></i> Promotions
                </a>
                <div class="dropdown-menu dropdown-menu-arrow dropright">
                  <router-link
                    v-if="can(uiPermissions.COUPONS_VIEW)"
                    to="/coupons"
                    data-test="link-coupons"
                    class="dropdown-item">
                    <i class="dropdown-icon fe fe-heart"></i> Coupons
                  </router-link>
                  <router-link
                    v-if="can(uiPermissions.STORE_CREDIT_VIEW)"
                    to="/store-credit"
                    data-test="link-store-credit"
                    class="dropdown-item">
                    <i class="dropdown-icon fe fe-dollar-sign"></i> Store credit
                  </router-link>
                  <router-link
                    v-if="can(uiPermissions.DISCOUNTS_VIEW)"
                    to="/discounts"
                    data-test="link-discounts"
                    class="dropdown-item">
                    <i class="dropdown-icon fe fe-percent"></i> Discounts
                  </router-link>
                  <router-link
                    v-if="can(uiPermissions.REFERRAL_VIEW)"
                    to="/referrals"
                    data-test="link-referrals"
                    class="dropdown-item">
                    <i class="dropdown-icon fe fe-share-2"></i> Referrals
                  </router-link>
                </div>
              </li>
              <li
                v-if="can([uiPermissions.ARTICLES_VIEW, uiPermissions.ARTICLES_COST_PRICE_CHANGES_VIEW, uiPermissions.ARTICLES_COMPOSITIONS_VIEW, uiPermissions.SUPPLIERS_VIEW, uiPermissions.BRANDS_VIEW, uiPermissions.CATEGORIES_VIEW, uiPermissions.SWAP_TOOL_VIEW, uiPermissions.PURCHASE_ORDERS_VIEW])"
                class="nav-item dropdown-toggle">
                <a
                  href="javascript:void(0)"
                  data-test="link-procurement"
                  class="nav-link"
                  :class="{
                    'active': ['articles', 'articlePriceApprovals', 'articleCompositions', 'suppliers', 'brands', 'categories', 'swaps', 'purchaseOrder'].includes($route.meta.name)
                  }"
                  data-toggle="dropdown">
                  <i class="fe fe-map"></i> Procurement
                </a>
                <div class="dropdown-menu dropdown-menu-arrow dropright">
                  <router-link
                    v-if="can(uiPermissions.ARTICLES_VIEW)"
                    to="/articles"
                    data-test="link-articles"
                    class="dropdown-item">
                    <i class="dropdown-icon fe fe-paperclip"></i> Articles
                  </router-link>
                  <router-link
                    v-if="can(uiPermissions.ARTICLES_COST_PRICE_CHANGES_VIEW)"
                    to="/article-price-approvals"
                    data-test="link-article-price-approvals"
                    class="dropdown-item">
                    <i class="dropdown-icon fe fe-dollar-sign"/> Article price approvals
                  </router-link>
                  <router-link
                    v-if="can(uiPermissions.ARTICLES_COMPOSITIONS_VIEW)"
                    to="/article-compositions"
                    data-test="link-article-compositions"
                    class="dropdown-item">
                    <i class="dropdown-icon fe fe-list"/> Article compositions
                  </router-link>
                  <router-link
                    v-if="can(uiPermissions.SUPPLIERS_VIEW)"
                    to="/suppliers"
                    data-test="link-suppliers"
                    class="dropdown-item">
                    <i class="dropdown-icon fe fe-users"></i> Suppliers
                  </router-link>
                  <router-link
                    v-if="can(uiPermissions.BRANDS_VIEW)"
                    to="/brands"
                    data-test="link-brands"
                    class="dropdown-item">
                    <i class="dropdown-icon fe fe-tv"></i> Brands
                  </router-link>
                  <router-link
                    v-if="can(uiPermissions.CATEGORIES_VIEW)"
                    to="/categories"
                    data-test="link-categories"
                    class="dropdown-item">
                    <i class="dropdown-icon fe fe-grid"></i> Categories
                  </router-link>
                  <router-link
                    v-if="can(uiPermissions.SWAP_TOOL_VIEW)"
                    to="/swaps"
                    data-test="link-swap-tool"
                    class="dropdown-item">
                    <i class="dropdown-icon fe fe-repeat"></i> Swap tool
                  </router-link>
                  <a
                    v-if="can([
                      uiPermissions.PURCHASE_ORDERS_REPORT_VIEW,
                      uiPermissions.PURCHASE_ORDERS_ROSTER_VIEW,
                      uiPermissions.PURCHASE_ORDERS_MANUAL_VIEW,
                    ])"
                    href="javascript:void(0)"
                    data-test="link-purchase-orders"
                    class="dropdown-item d-flex align-items-center justify-content-between"
                    data-toggle="dropdown">
                    <div>
                      <i class="dropdown-icon fe fe-box"></i> Purchase orders
                    </div>
                    <div class="dropdown-toggle"></div>
                  </a>
                  <div class="dropdown-menu">
                    <router-link
                      v-if="can(uiPermissions.PURCHASE_ORDERS_REPORT_VIEW)"
                      to="/purchase-order/reports"
                      data-test="link-purchase-order-report"
                      class="dropdown-item">
                      Purchase order report
                    </router-link>
                    <router-link
                      v-if="can(uiPermissions.PURCHASE_ORDERS_ROSTER_VIEW)"
                      to="/purchase-order/new"
                      data-test="link-create-roster-po"
                      class="dropdown-item">
                      Create roster purchase order
                    </router-link>
                    <router-link
                      v-if="can(uiPermissions.PURCHASE_ORDERS_MANUAL_VIEW)"
                      to="/manual-purchase-order/new"
                      data-test="link-create-manual-po"
                      class="dropdown-item">
                      Create manual purchase
                      order
                    </router-link>
                  </div>
                </div>
              </li>
              <li
                v-if="can([uiPermissions.INTERNAL_PURCHASE_VIEW, uiPermissions.INBOUND_TOOL_VIEW, uiPermissions.STOCK_ON_HAND_VIEW, uiPermissions.MANUAL_GRN_VIEW])"
                class="nav-item dropdown-toggle">
                <a
                  href="javascript:void(0)"
                  data-test="link-operations"
                  class="nav-link"
                  :class="{
                    'active': ['shoppingCarts', 'operations', 'stockOnHand', 'manualGRN'].includes($route.meta.name)
                  }"
                  data-toggle="dropdown">
                  <i class="fe fe-settings"/> Operations
                </a>
                <div class="dropdown-menu dropdown-menu-arrow dropright">
                  <router-link
                    v-if="can(uiPermissions.INTERNAL_PURCHASE_VIEW)"
                    to="/inventory/shopping"
                    data-test="link-shopping-cart"
                    class="dropdown-item">
                    <i class="dropdown-icon fe fe-shopping-cart"></i> Shopping cart
                  </router-link>
                  <router-link
                    v-if="can(uiPermissions.MANUAL_GRN_VIEW)"
                    to="/operations/manual-grn"
                    data-test="link-manual-grn"
                    class="dropdown-item">
                    <i class="dropdown-icon fe fe-box"></i> Manual GRN
                  </router-link>
                  <div v-if="can(uiPermissions.STOCK_ON_HAND_VIEW)" class="dropdown w-100">
                    <a
                      href="javascript:void(0)"
                      data-test="link-stock-on-hand"
                      class="dropdown-item d-flex align-items-center justify-content-between">
                      <div><i class="dropdown-icon fe fe-clipboard"></i> Stock on hand</div>
                      <div class="dropdown-toggle"></div>
                    </a>
                    <div v-if="can([uiPermissions.REPORTS_INGREDIENTS_VIEW, uiPermissions.REPORTS_INGREDIENTS_CREATE])" class="dropdown-menu sub-dropdown-menu">
                      <router-link
                        v-if="can(uiPermissions.REPORTS_INGREDIENTS_VIEW)"
                        to="/inventory/reports/stocks/articles"
                        data-test="link-soh-summary"
                        class="dropdown-item">
                        Summary
                      </router-link>
                      <router-link
                        v-if="can(uiPermissions.REPORTS_INGREDIENTS_CREATE)"
                        to="/inventory/stocks/articles"
                        data-test="link-add-stocks"
                        class="dropdown-item">
                        Add stocks
                      </router-link>
                    </div>
                  </div>
                </div>
              </li>
              <li
                v-if="can([uiPermissions.TOOLS_ACTIONS_VIEW, uiPermissions.TOOLS_EXPORT_VIEW, uiPermissions.MISCELLANEOUS_ACTIVITY_LOGS])"
                class="nav-item dropdown-toggle">
                <a
                  href="javascript:void(0)"
                  data-test="link-tools"
                  class="nav-link"
                  :class="{
                    'active': ['actions', 'export', 'logs'].includes($route.meta.name)
                  }"
                  data-toggle="dropdown"><i class="fe fe-sliders"></i> Tools
                </a>
                <div class="dropdown-menu dropdown-menu-arrow dropright">
                  <router-link
                    v-if="can(uiPermissions.TOOLS_ACTIONS_VIEW)"
                    to="/actions"
                    data-test="link-actions"
                    class="dropdown-item">
                    <i class="dropdown-icon fe fe-at-sign"></i> Actions
                  </router-link>
                  <router-link
                    v-if="can(uiPermissions.TOOLS_EXPORT_VIEW)"
                    to="/export"
                    data-test="link-export"
                    class="dropdown-item">
                    <i class="dropdown-icon fe fe-download-cloud"></i> Export
                  </router-link>
                  <router-link
                    v-if="can(uiPermissions.PAYMENT_RECONCILIATIONS_VIEW)"
                    to="/payment-reconciliation"
                    data-test="link-payment-reconciliation"
                    class="dropdown-item">
                    <i class="dropdown-icon fe fe-dollar-sign"></i> Payment reconciliation
                  </router-link>
                  <router-link
                    v-if="can(uiPermissions.MISCELLANEOUS_ACTIVITY_LOGS)"
                    to="/logs"
                    data-test="link-logs"
                    class="dropdown-item">
                    <i class="dropdown-icon fe fe-activity"></i> Activity log
                  </router-link>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>

import {mapState} from 'vuex';
import IconTheme from '@/components/icons/IconTheme.vue';

const includeENV = ['salt', 'pepper'];
const ENV = includeENV.includes(process.env.VUE_APP_ENV) ? process.env.VUE_APP_ENV : null;
const ENV_ICON = includeENV.includes(process.env.VUE_APP_ENV) ? require(`@/assets/svg/${ENV}.svg`) : null;

export default {
  components: {
    IconTheme,
  },
  data() {
    return {
      openNotif: false,
      query: '',
      ENV,
      ENV_ICON,
    };
  },
  computed: {
    ...mapState({
      customer: state => state.auth.customer,
      user: state => (state.auth.customer || {}).user,
    }),
  },
  created() {
    const params = new URLSearchParams(window.location.search);

    if (params.has('query')) { this.query = params.get('query').trim(); }
  },
  methods: {
    checkNotif() {
      this.openNotif = !this.openNotif;

      if (this.openNotif) { this.$store.dispatch('auth/restore'); }
    },
    search() {
      const query = Object.assign({}, this.$route.query, {

        query: this.query !== '' ? this.query.trim() : undefined,
      });

      if (this.$route.meta.search && this.$route.path !== this.$route.meta.search) {
        this.$router.push({

          path: this.$route.meta.search,
          query,
        });
      }
      else { this.$router.replace({query}); }
    },
  },
};

</script>

<style scoped>
.notifications .dropdown-item.active {

  background-color: #ffffff;
  color: #6e7687;
}

.dropdown-item.active .fe {
  color: #fff;
}

.nav-link:focus,
.dropdown-toggle:focus {
  outline: none;
}

.dropdown-item {
  line-height: 1.6;
}

.dropdown {
  position: relative;
}

.sub-dropdown-menu {
  display: none;
  position: absolute;
  left: 100%;
  top: -10px;
}

.dropdown:hover .sub-dropdown-menu,
.sub-dropdown-menu:hover {

  display: block;
}

.dropdown-item.active .theme-icon >>> path {
  fill: #fff;
}
</style>
