import Articles from '@hellochef/shared-js/services/Articles';
import ArticleRequisitions from '@hellochef/shared-js/services/ArticleRequisitions';
import ArticleForecast from '@hellochef/shared-js/services/ArticleForecast';
import Auth from '@hellochef/shared-js/services/Auth';
import Customers from '@hellochef/shared-js/services/Customers';
import Images from '@hellochef/shared-js/services/Images';
import Ingredients from '@hellochef/shared-js/services/Ingredients';
import MaterialTypes from '@hellochef/shared-js/services/MaterialTypes';
import Inventory from '@hellochef/shared-js/services/Inventory';
import Market from '@hellochef/shared-js/services/Market';
import Menus from '@hellochef/shared-js/services/Menus';
import Orders from '@hellochef/shared-js/services/Orders';
import PurchaseOrders from '@hellochef/shared-js/services/PurchaseOrders';
import PurchaseOrdersRoster from '@hellochef/shared-js/services/PurchaseOrdersRoster';
import Payments from '@hellochef/shared-js/services/Payments';
import Products from '@hellochef/shared-js/services/Products';
import Recipes from '@hellochef/shared-js/services/Recipes';
import Referrals from '@hellochef/shared-js/services/Referrals';
import Reports from '@hellochef/shared-js/services/Reports';
import Resource from '@hellochef/shared-js/services/Resource';
import Subscriptions from '@hellochef/shared-js/services/Subscriptions';
import Stocks from '@hellochef/shared-js/services/Stocks';
import Suppliers from '@hellochef/shared-js/services/Suppliers';
import Tags from '@hellochef/shared-js/services/Tags';
import Wastages from '@hellochef/shared-js/services/Wastages';
import WeeklyMenus from '@hellochef/shared-js/services/WeeklyMenus';
import InternalPurchaseList from '@hellochef/shared-js/services/InternalPurchaseList';
import MlForecast from '@hellochef/shared-js/services/machine-learning/Forecast';
import PaymentReconciliations from '@hellochef/shared-js/services/PaymentReconciliations';
import $axios from './axios';
import $axiosMl from './axios-ml';

const activitylogs = new Resource({$axios, endpoint: 'activitylogs', entity: 'activitylog'});
const activitylogModels = new Resource({$axios, endpoint: 'activitylogs/models/list', entity: ''});
const activitylogSubject = new Resource({$axios, endpoint: 'activitylogs/for/subject', entity: 'activitylog'});
const adminLogin = new Resource({$axios, endpoint: 'admin-login', entity: 'customer'});
const articles = new Articles({$axios, endpoint: 'articles', entity: 'article'});
const articlesDropdown = new Resource({$axios, endpoint: 'articles/dropdown'});
const articleForecast = new ArticleForecast({$axios});
const articleRequisitions = new ArticleRequisitions({$axios});
const auth = new Auth({$axios});
const archivedRecipes = new Resource({$axios, endpoint: 'recipes/archived', entity: 'recipe'});
const boxesForecastExport = new Resource({$axios, endpoint: 'forecast/boxes'});
const brands = new Resource({$axios, entity: 'brand'});
const categories = new Resource({$axios, entity: 'category', plural: 'categories'});
const countries = new Resource({$axios, entity: 'country', plural: 'countries'});
const customerProfiles = new Resource({$axios, entity: 'customerProfile', endpoint: 'customer-profiles'});
const churnPrediction = new Resource({$axios, endpoint: 'customers/churn-prediction'});
const complaints = new Resource({$axios, endpoint: 'complaints', entity: 'complaints'});
const coupons = new Resource({$axios, entity: 'coupon'});
const customers = new Customers({$axios});
const deliveryCities = new Resource({$axios, endpoint: 'delivery/cities', entity: 'deliveryCity', plural: 'deliveryCities'});
const deliveryDays = new Resource({$axios, endpoint: 'delivery/days', entity: 'deliveryDay'});
const deliveryTimes = new Resource({$axios, endpoint: 'delivery/times', entity: 'deliveryTime'});
const discounts = new Resource({$axios, entity: 'discount'});
const grn = new Resource({$axios, endpoint: 'grn'});
const images = new Images({$axios});
const imagesPrivate = new Images({$axios, endpoint: 'images/private'});
const ingredients = new Ingredients({$axios});
const ingredientRequests = new Resource({$axios, endpoint: 'inventory/ingredient-requests', entity: 'ingredientRequest'});
const internalPurchase = new Resource({$axios, endpoint: id => `inventory/internal-purchase/${id}/recipes`});
const internalPurchaseList = new InternalPurchaseList({$axios});
const internalPurchaseArticles = new Resource({$axios, endpoint: id => `inventory/internal-purchase/${id}/articles`});
const inventory = new Inventory({$axios});
const inventoryIngredients = new Resource({$axios, endpoint: 'inventory/reports/stock/ingredients', entity: 'inventoryIngredient'});
const inventoryIngredientsGrouped = new Resource({$axios, endpoint: 'inventory/reports/stock/ingredients/grouped', entity: 'inventoryIngredient'});
const inventoryPackageItems = new Resource({$axios, endpoint: 'inventory/reports/stock/package-items', entity: 'inventoryPackageItem'});
const mainIngredients = new Resource({$axios, endpoint: 'v2/ingredients', entity: 'ingredient'});
const materialTypes = new MaterialTypes({$axios});
const mealPlans = new Resource({$axios, endpoint: 'meals', entity: 'mealPlan'});
const mealPreferences = new Resource({$axios, endpoint: 'meals/preferences', entity: 'mealPreference'});
const menus = new Menus({$axios});
const notifications = new Resource({$axios, endpoint: '/notifications', entity: 'notification'});
const orders = new Orders({$axios});
const orderComplaints = new Resource({$axios, endpoint: 'order/complaints', entity: 'complaint'});
const orderChangeRequest = new Resource({$axios, endpoint: 'order/change-requests', entity: 'changeRequest'});
const ordersNew = new Resource({$axios, endpoint: '/orders/new-orders-index', entity: 'order'});
const packagingMaterials = new Resource({$axios, endpoint: '/packaging-materials', entity: 'packaging_material'});
const packagingMethods = new Resource({$axios, endpoint: '/packaging-methods', entity: 'packagingMethod'});
const payments = new Payments({$axios});
const products = new Products({$axios});
const purchaseOrders = new PurchaseOrders({$axios});
const purchaseOrderRoster = new PurchaseOrdersRoster({$axios});
const recipeCards = new Resource({$axios, endpoint: 'recipes/card-update', entity: 'cardUpdate'});
const recipeEquipments = new Resource({$axios, endpoint: 'recipes/equipments', entity: 'equipment'});
const recipeFeatures = new Resource({$axios, endpoint: 'recipes/features', entity: 'feature'});
const recipeProteins = new Resource({$axios, endpoint: 'recipes/proteins', entity: 'protein'});
const recipeReviews = new Resource({$axios, endpoint: 'recipe-reviews', entity: 'review'});
const recipePendingReview = new Resource({$axios, endpoint: 'recipe-reviews/pending', entity: 'pendingReview'});
const recipes = new Recipes({$axios});
const recipeSwaps = new Resource({$axios, endpoint: 'recipe-swaps'});
const reports = new Reports({$axios});
const referrals = new Referrals({$axios});
const standardized = new Resource({$axios, endpoint: 'ingredients/standardized', entity: 'standardizedIngredient'});
const stocks = new Stocks({$axios});
const storeCredit = new Resource({$axios, endpoint: 'store-credit', entity: 'storeCredit'});
const subscriptions = new Subscriptions({$axios});
const suppliers = new Suppliers({$axios, entity: 'supplier'});
const swaps = new Resource({$axios, endpoint: 'swap'});
const swapsMasterIngredient = new Resource({$axios, endpoint: 'swap/ingredients-in-master-recipes'});
const swappable = new Resource({$axios, endpoint: 'swap/swappable-recipes', entity: 'recipe'});
const swappableInMaster = new Resource({$axios, endpoint: 'swap/ingredients-in-master-recipes'});
const swappableClones = new Resource({$axios, endpoint: 'swap/clone-articles', entity: 'articles'});
const uom = new Resource({$axios, endpoint: '/uom', entity: 'unitOfMeasure'});
const users = new Resource({$axios, endpoint: '/users', entity: 'user'});
const tags = new Tags({$axios, entity: 'tag'});
const themes = new Resource({$axios, endpoint: 'themes', entity: 'theme'});
const wastages = new Wastages({$axios});
const weekDays = new Resource({$axios, entity: 'weekday'});
const weeklyMenus = new WeeklyMenus({$axios});
const weeklyMenusBasic = new Resource({$axios, endpoint: '/weekly-menus/listing', entity: 'weeklyMenu'});
const weeklyMenusFind = new Resource({$axios, endpoint: 'weekly-menus/find', entity: 'weeklyMenu'});
const recipesListForSwap = new Resource({$axios, endpoint: 'recipes/list-by-recipe-ingredient-attributes', entity: 'recipes'});
const internalRecipesExport = new Resource({$axios, endpoint: 'inventory/internal-purchase/export/recipes'});
const internalArticlesExport = new Resource({$axios, endpoint: 'inventory/internal-purchase/export/articles'});
const internalRecipesIngredientsPortioningExport = new Resource({$axios, endpoint: 'inventory/internal-purchase/export/recipes-ingredients-portioning'});
const internalArticlesPurchaseOrder = new Resource({$axios, endpoint: 'inventory/internal-purchase/send-purchase-order'});
const internalRecipesPrint = new Images({$axios, endpoint: 'inventory/internal-purchase/export/recipe-cards'});
const articleCompositions = new Resource({$axios, endpoint: 'articles/compositions', entity: 'composition'});
const travelMode = new Resource({$axios, endpoint: '/travel-modes', entity: 'travelMode'});
const tradingTerms = new Resource({$axios, endpoint: 'trading-terms', entity: 'trading_term'});
const roles = new Resource({$axios, endpoint: '/roles', entity: 'role'});
const permissions = new Resource({$axios, endpoint: '/permissions', entity: 'permission'});
const repeatOffenders = new Resource({$axios, endpoint: '/repeat-offenders', entity: 'repeatOffender'});
const market = new Market({$axios});
const paymentReconciliations = new PaymentReconciliations({$axios});

// machine leaning services
const mlForecast = new MlForecast({$axiosMl});

export {
  activitylogs,
  activitylogModels,
  activitylogSubject,
  adminLogin,
  auth,
  archivedRecipes,
  articles,
  articlesDropdown,
  articleForecast,
  articleRequisitions,
  boxesForecastExport,
  brands,
  categories,
  countries,
  churnPrediction,
  complaints,
  coupons,
  customers,
  customerProfiles,
  deliveryCities,
  deliveryDays,
  deliveryTimes,
  discounts,
  grn,
  images,
  imagesPrivate,
  ingredients,
  ingredientRequests,
  internalPurchase,
  inventory,
  inventoryIngredients,
  inventoryIngredientsGrouped,
  inventoryPackageItems,
  mainIngredients,
  materialTypes,
  mealPlans,
  mealPreferences,
  menus,
  notifications,
  orders,
  orderComplaints,
  orderChangeRequest,
  ordersNew,
  packagingMaterials,
  packagingMethods,
  payments,
  products,
  purchaseOrders,
  purchaseOrderRoster,
  recipePendingReview,
  recipeReviews,
  recipes,
  recipeCards,
  recipeEquipments,
  recipeFeatures,
  recipeProteins,
  recipeSwaps,
  referrals,
  reports,
  standardized,
  stocks,
  storeCredit,
  subscriptions,
  suppliers,
  swaps,
  swapsMasterIngredient,
  swappable,
  swappableInMaster,
  swappableClones,
  uom,
  users,
  tags,
  themes,
  wastages,
  weekDays,
  weeklyMenus,
  weeklyMenusBasic,
  weeklyMenusFind,
  recipesListForSwap,
  internalPurchaseList,
  internalPurchaseArticles,
  internalRecipesExport,
  internalArticlesExport,
  internalRecipesIngredientsPortioningExport,
  internalArticlesPurchaseOrder,
  internalRecipesPrint,
  articleCompositions,
  mlForecast,
  tradingTerms,
  roles,
  permissions,
  travelMode,
  repeatOffenders,
  market,
  paymentReconciliations,
};
