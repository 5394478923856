import Permissions from '@hellochef/shared-js/enums/Permissions';

export default [
  {
    path: '/operations/manual-grn',
    component: () => import('@/views/manualGRN/Index.vue'),
    meta: {
      name: 'manualGRN',
      requiresAuth: true,
      permissions: {
        view: Permissions.MANUAL_GRN_VIEW,
        create: false,
        update: false,
        delete: false,
      },
    },
  },
  {
    path: '/operations/manual-grn/:id',
    component: () => import('@/views/manualGRN/Edit.vue'),
    meta: {
      name: 'manualGRN',
      requiresAuth: true,
      permissions: {
        view: Permissions.MANUAL_GRN_VIEW,
        create: false,
        update: false,
        delete: false,
      },
    },
  },
];
