<template>

  <div class="alert alert-icon alert-dismissible" :class="`alert-${type}`">
    <button
      v-if="canBeClosed"
      type="button"
      class="close"
      @click="$emit('close')"></button>
    <i
      class="fe mr-2"
      :class="icons[type]"
      aria-hidden="true"></i>
    <slot>{{message}}</slot>
  </div>

</template>

<script>

export default {
  props: {
    message: {
      default: '',
      type: String,
    },
    type: {
      default: 'info',
      type: String,
    },
    canBeClosed: {
      default: true,
      type: Boolean,
    },
  },

  data() {
    return {

      icons: {

        danger: 'fe-alert-triangle',
        info: 'fe-info',
        success: 'fe-check',
        warning: 'fe-alert-triangle',
      },
    };
  },
};

</script>
