<template>
  <div v-show="showModal" class="modal-container">
    <slot></slot>
    <i
      v-if="withCloseIcon"
      class="fe fe-x close-icon"
      @click="closeModal"></i>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    withCloseIcon: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    showModal() {
      return this.visible;
    },
  },
  watch: {
    visible: function(val) {
      if (val) {
        document.body.classList.add('modal-open');
      }
      else {
        document.body.classList.remove('modal-open');
      }
    },
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<style>

    body.modal-open {
      overflow: hidden;
    }

    .modal-container {
        position: fixed;
        z-index: 100;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgba(0,0,0,0.6);
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

    .close-icon{
        color: #fff;
        font-weight: bold;
        font-size: 20px;
        z-index: 999999999;
        position: absolute;
        right: 10%;
        top: 10%
    }

    .close-icon:hover {
        cursor: pointer;
    }

</style>
