import Permissions from '@hellochef/shared-js/enums/Permissions';

export default [
  {
    path: '/articles',
    component: () => import('@/views/articles/Index.vue'),
    meta: {
      name: 'articles',
      requiresAuth: true,
      permissions: {
        view: Permissions.ARTICLES_VIEW,
        create: false,
        update: Permissions.ARTICLES_UPDATE,
        delete: false,
      },
    },
  },
  {
    path: '/articles/:id',
    component: () => import('@/views/articles/Edit.vue'),
    meta: {
      name: 'articles',
      requiresAuth: true,
      search: '/articles',
      permissions: {
        view: Permissions.ARTICLES_VIEW,
        create: Permissions.ARTICLES_DUPLICATE,
        update: Permissions.ARTICLES_UPDATE,
        delete: false,
      },
    },
  },
  {
    path: '/article-price-approvals',
    component: () => import('@/views/articlePriceApprovals/Index.vue'),
    meta: {
      name: 'articlePriceApprovals',
      requiresAuth: true,
      permissions: {
        view: Permissions.ARTICLES_COST_PRICE_CHANGES_VIEW,
        create: false,
        update: Permissions.ARTICLES_COST_PRICE_CHANGES_UPDATE,
        delete: false,
      },
    },
  },
  {
    path: '/article-compositions',
    component: () => import('@/views/articleCompositions/Index.vue'),
    meta: {
      name: 'articleCompositions',
      requiresAuth: true,
      permissions: {
        view: Permissions.ARTICLES_COMPOSITIONS_VIEW,
        create: Permissions.ARTICLES_COMPOSITIONS_CREATE,
        update: Permissions.ARTICLES_COMPOSITIONS_UPDATE,
        delete: Permissions.ARTICLES_COMPOSITIONS_DELETE,
      },
    },
  },
  {
    path: '/article-compositions/:id',
    component: () => import('@/views/articleCompositions/Edit.vue'),
    meta: {
      name: 'articleCompositions',
      requiresAuth: true,
      search: '/article-components',
      permissions: {
        view: Permissions.ARTICLES_COMPOSITIONS_VIEW,
        create: Permissions.ARTICLES_COMPOSITIONS_CREATE,
        update: Permissions.ARTICLES_COMPOSITIONS_UPDATE,
        delete: Permissions.ARTICLES_COMPOSITIONS_DELETE,
      },
    },
  },
  {
    path: '/suppliers',
    component: () => import('@/views/suppliers/Index.vue'),
    meta: {
      name: 'suppliers',
      requiresAuth: true,
      permissions: {
        view: Permissions.SUPPLIERS_VIEW,
        create: Permissions.SUPPLIERS_CREATE,
        update: Permissions.SUPPLIERS_UPDATE,
        delete: false,
      },
    },
  },
  {
    path: '/suppliers/:id',
    component: () => import('@/views/suppliers/Edit.vue'),
    meta: {
      name: 'suppliers',
      requiresAuth: true,
      search: '/suppliers',
      permissions: {
        view: Permissions.SUPPLIERS_VIEW,
        create: Permissions.SUPPLIERS_CREATE,
        update: Permissions.SUPPLIERS_UPDATE,
        delete: false,
      },
    },
  },
  {
    path: '/suppliers/:supplierId/trading-terms/:id',
    component: () => import('@/views/suppliers/tradingTerms/Edit.vue'),
    meta: {
      name: 'tradingTerms',
      requiresAuth: true,
      search: '/trading-terms',
      permissions: {
        view: Permissions.SUPPLIERS_ATTACHMENTS_VIEW,
        create: Permissions.SUPPLIERS_TRADING_TERMS_CREATE,
        update: Permissions.SUPPLIERS_TRADING_TERMS_UPDATE,
        delete: false,
      },
    },
  },
  {
    path: '/brands',
    component: () => import('@/views/brands/Index.vue'),
    meta:
    {
      name: 'brands',
      requiresAuth: true,
      permissions: {
        view: Permissions.BRANDS_VIEW,
        create: Permissions.BRANDS_CREATE,
        update: Permissions.BRANDS_UPDATE,
        delete: false,
      },
    },
  },
  {
    path: '/brands/:id',
    component: () => import('@/views/brands/Edit.vue'),
    meta:
    {
      name: 'brands',
      requiresAuth: true,
      search: '/brands',
      permissions: {
        view: Permissions.BRANDS_VIEW,
        create: Permissions.BRANDS_CREATE,
        update: Permissions.BRANDS_UPDATE,
        delete: false,
      },
    },
  },
  {
    path: '/categories',
    component: () => import('@/views/categories/Index.vue'),
    meta: {
      name: 'categories',
      requiresAuth: true,
      permissions: {
        view: Permissions.CATEGORIES_VIEW,
        create: Permissions.CATEGORIES_CREATE,
        update: Permissions.CATEGORIES_UPDATE,
        delete: false,
      },
    },
  },
  {
    path: '/categories/:id',
    component: () => import('@/views/categories/Edit.vue'),
    meta: {
      name: 'categories',
      requiresAuth: true,
      search: '/categories',
      permissions: {
        view: Permissions.CATEGORIES_VIEW,
        create: Permissions.CATEGORIES_CREATE,
        update: Permissions.CATEGORIES_UPDATE,
        delete: false,
      },
    },
  },
  {
    path: '/swaps',
    component: () => import('@/views/swaps/Index.vue'),
    meta: {
      name: 'swaps',
      requiresAuth: true,
      permissions: {
        view: Permissions.SWAP_TOOL_VIEW,
        create: false,
        update: false,
        delete: false,
      },
    },
  },
  {
    path: '/purchase-order/reports',
    component: () => import('@/views/purchaseOrder/reports/Index.vue'),
    meta: {
      name: 'purchaseOrder',
      requiresAuth: true,
      permissions: {
        view: Permissions.PURCHASE_ORDERS_REPORT_VIEW,
        create: false,
        update: false,
        delete: false,
      },
    },
  },
  {
    path: '/purchase-order/reports/:id',
    component: () => import('@/views/purchaseOrder/reports/Edit.vue'),
    meta: {
      name: 'purchaseOrder',
      requiresAuth: true,
      hideSearch: true,
      permissions: {
        view: Permissions.PURCHASE_ORDERS_REPORT_VIEW,
        create: false,
        update: false,
        delete: false,
      },
    },
  },
  {
    path: '/purchase-order/:id',
    component: () => import('@/views/purchaseOrder/Edit.vue'),
    meta: {
      name: 'purchaseOrder',
      requiresAuth: true,
      permissions: {
        view: Permissions.PURCHASE_ORDERS_ROSTER_VIEW,
        create: Permissions.PURCHASE_ORDERS_CREATE,
        update: false,
        delete: false,
      },
    },
  },
  {
    path: '/purchase-order/invoice/:id',
    component: () => import('@/views/purchaseOrder/Invoice.vue'),
    meta: {
      name: 'purchaseOrder',
      requiresAuth: true,
      permissions: {
        view: Permissions.PURCHASE_ORDERS_VIEW,
        create: false,
        update: false,
        delete: false,
      },
    },
  },
  {
    path: '/manual-purchase-order/new',
    component: () => import('@/views/manualPurchaseOrder/Edit.vue'),
    meta: {
      name: 'purchaseOrder',
      requiresAuth: true,
      permissions: {
        view: Permissions.PURCHASE_ORDERS_MANUAL_VIEW,
        create: false,
        update: false,
        delete: false,
      },
    },
  },
];
