import Permissions from '@hellochef/shared-js/enums/Permissions';

export default [
  {
    path: '/actions',
    component: () => import('@/views/actions/Index.vue'),
    meta: {
      name: 'actions',
      requiresAuth: true,
      permissions: {
        view: Permissions.TOOLS_ACTIONS_VIEW,
        create: false,
        update: false,
        delete: false,
      },
    },
  },
  {
    path: '/export',
    component: () => import('@/views/Export.vue'),
    meta: {
      name: 'export',
      requiresAuth: true,
      permissions: {
        view: Permissions.TOOLS_EXPORT_VIEW,
        create: false,
        update: false,
        delete: false,
      },
    },
  },
  {
    path: '/payment-reconciliation',
    component: () => import('@/views/paymentReconciliation/Index.vue'),
    meta: {
      name: 'payment-reconciliation',
      requiresAuth: true,
      permissions: {
        view: Permissions.PAYMENT_RECONCILIATIONS_VIEW,
        create: false,
        update: false,
        delete: false,
      },
    },
  },
  {
    path: '/logs',
    component: () => import('@/views/logs/Index.vue'),
    meta: {
      name: 'logs',
      requiresAuth: true,
      permissions: {
        view: Permissions.MISCELLANEOUS_ACTIVITY_LOGS,
        create: false,
        update: false,
        delete: false,
      },
    },
  },
];
